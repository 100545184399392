<template>
  <img class="phone" src="../assets/phone.png" @click="onCall">
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(['brand'])
  },
  methods: {
    onCall() {
      if (this.brand.serviceInfo) {
        window.location.href = `tel://${this.brand.serviceInfo.ccphone}`;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.phone {
  height: 30px;
}
</style>
